import React from "react";

function CommonHOCComponent(WrappedComponent: any) {
    return function (props: any) {
        return <WrappedComponent
            {...props}
        />;
    }
}

export default CommonHOCComponent;