import { ISmsSentModalProps } from '../../../lib/types/components';
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import styledComponent from './smsCss';

const SmsSentModal = (props: ISmsSentModalProps) => {

    const {smsSent, smsSentMsg, setSmsSent, number, smsSentModalData} = props;

    const handleCancelClick = (e) => {
        setSmsSent(false);
        const html: any = document.querySelector("html");
        html.style.overflowY = "auto";
    }

    return (
        smsSentModalData ?
            <>
                <style jsx scoped>{
                    styledComponent
                }
                </style>
                <style jsx>{`
                        
                        .modalContainer{
                            display: flex;
                            flex-direction: column;
                            width: 1132px;
                            height:auto;
                            border-radius: 10px;
                            background-color: #fff;
                            position: relative;
                            box-shadow: 2px 2px 2px 2px dimgray;
                        }
                        
                        .smsDetailsContainer{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            margin-top: 42px;
                        }
                        
                        .smsDetailsContainer > span{
                            margin-right: 18px;
                        }
                        
                        .smsDetailsContainer > p{
                            font-size: 42px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.95;
                            letter-spacing: -0.67px;
                            color: #333;
                        }
                        
                        .smsDetailsContainer > p > span{
                            margin-left: 10px;
                            font-size: 42px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.95;
                            letter-spacing: -0.67px;
                            color: #2aae5a;
                        }
                        
                        .lineSeperator{
                            margin-top: 10px;
                            display: flex;
                            justify-content: center;
                        }
                        
                        .qrContainer {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-top: 50px;
                            padding: 0 30px 10px 30px;
                        }
                        
                        .textContainer{
                            margin-left: 10px;
                            display: flex;
                            flex-direction: column;
                        }
                        
                        .textContainer > p:nth-child(1){
                            font-size: 36px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.11;
                            letter-spacing: -0.58px;
                            color: rgba(112, 23, 141, 0.85);
                            margin-top: 30px;
                        }
                        
                        .textContainer > p:nth-child(2){
                            font-size: 32px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.25;
                            letter-spacing: -0.51px;
                            color: rgba(51, 51, 51, 0.85);
                            width: 596px;
                        }
                        
                        .cancelButtonContainer{
                            position: absolute;
                            right: 0;
                            top: -40px;
                            cursor: pointer;
                        }   

                        @media screen and (max-width: 767px) {
                            .modalContainer{
                                display: none !important;
                            }   
                        }
                        
                       
                `}
                </style>
                <div
                    onClick={handleCancelClick}
                    className={smsSent ? ((navigator.userAgent.indexOf("Firefox") !== -1) ? "containerOpenFirefox" : "containerOpen") : "containerClose"}
                >
                    <div
                        className={"modalContainer"}
                    >
                        <div className={"smsDetailsContainer"}>
                            <span>
                                <CustomImageComponent
                                    src={"https://d3g4wmezrjkwkg.cloudfront.net/website/images/green_tick_image.webp"}
                                    width={48}
                                    height={48}
                                    alt={"sms-successful-sent"}
                                />
                            </span>
                            <p>{smsSentMsg}<span>{number}</span></p>
                        </div>
                        <div className={"lineSeperator"}>
                            <CustomImageComponent
                                src={"https://d3g4wmezrjkwkg.cloudfront.net/website/images/gameFoulsLine.webp"}
                                width={800}
                                height={9}
                                alt={"sms-line"}
                            />
                        </div>
                        <div className={"qrContainer"}>
                            <div className={"textContainer"}>
                                <p>{smsSentModalData.text2}</p>
                                <p>
                                    {smsSentModalData.text3}
                                </p>
                            </div>
                            <div>
                                <CustomImageComponent
                                    src={smsSentModalData.qrCode}
                                    width={280}
                                    height={280}
                                    alt="sms-QR-code"
                                />
                            </div>
                        </div>
                        <div className={"cancelButtonContainer"} onClick={handleCancelClick}>
                            <CustomImageComponent
                                src={'https://d3g4wmezrjkwkg.cloudfront.net/website/images/cancel_botton_shape.webp'}
                                width={32}
                                height={32}
                                alt="sms-close-popup"
                            />
                        </div>
                    </div>
                </div>
            </> : <></>
    )

}

export default SmsSentModal;